import {Injectable} from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {Address} from "../../types/models";
import {DeliveryTypeSelectModalPage} from "../../modals/delivery-type-select-modal/delivery-type-select-modal.page";
import {DeliveryStorageService} from "../storage/delivery-storage.service";
import {AuthStorageService} from "../storage/auth-storage.service";
import {NavigationEnd, Router} from "@angular/router";
import {AddressService} from "../customer/address.service";
import {GlobalSettingService} from "./global-setting.service";
import {PushService} from "../core/push.service";
import {AppVersionService} from "./app-version.service";
import {CartService} from "../customer/cart.service";
import {PageNavigationService} from "./page-navigation.service";
import {AdminDeliveryLocationService} from "../admin/admin-delivery-location.service";
import {AdminTimeSlotService} from "../admin/admin-time-slot.service";
import {AdminOrderService} from "../admin/admin-order.service";
import {AuthService} from "./auth.service";
import {UserService} from "../customer/user.service";
import {StorageService} from "../storage/storage.service";
import * as moment from 'moment';
import {AdminTimeSlotGroupService} from "../admin/admin-time-slot-group.service";
import {LocationStorageService} from "../storage/location-storage.service";
import {ToggleStorageService} from "../storage/toggle-storage.service";
import {Observable, Subject, Subscription} from "rxjs";
import {TimeSlotService} from "../customer/time-slot.service";
import {StoreService} from "../customer/store.service";
import {IdleService} from "./idle.service";

@Injectable({
  providedIn: 'root'
})
export class StartUpService {

  private initialized: Subject<void> = new Subject<void>();
  routeSubscription: Subscription;
  watchedRoutes = [
    'product-category-list',
  ];

  constructor(
    private modalController: ModalController,
    private deliveryStorage: DeliveryStorageService,
    private authStorage: AuthStorageService,
    private router: Router,
    private addressService: AddressService,
    private globalSettingService: GlobalSettingService,
    private pushService: PushService,
    private appVersionService: AppVersionService,
    private cartService: CartService,
    private navigationService: PageNavigationService,
    private deliveryLocationService: AdminDeliveryLocationService,
    private adminTimeSlotService: AdminTimeSlotService,
    private timeSlotService: TimeSlotService,
    private orderService: AdminOrderService,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService,
    private timeSlotGroupService: AdminTimeSlotGroupService,
    private locationStorage: LocationStorageService,
    private toggleStorage: ToggleStorageService,
    private navController: NavController,
    private storeService: StoreService,
    private idleService: IdleService
  ) {
  }

  public get Initialized(): Observable<void> {
    return this.initialized.asObservable();
  }

  init() {
    this.deliveryLocationService.init();
    this.navigationService.init();
    this.orderService.init();
    this.adminTimeSlotService.init();
    this.timeSlotGroupService.init();
    this.pushService.init();
    this.cartService.init();
    this.idleService.init();
    this.authService.init();
    this.authService.LoggedIn.subscribe(async () => this.start());
    this.authService.Logout.subscribe(async () => this.stop());
  }

  async start() {
    this.userService.clearCache();
    await this.globalSettingService.clear();
    await this.locationStorage.clear();
    await this.authService.addLogoutListener();
    await this.updateUser();
    await this.navigate();
    await this.addRouteSubscription();
    this.initialized.next();
  }

  async stop() {
    this.userService.clearCache();
    await this.deliveryStorage.clear();
    await this.globalSettingService.clear();
    await this.locationStorage.clear();
    await this.toggleStorage.clear();
    await this.authService.removeLogoutListener();
    await this.removeRouteSubscription();
    await this.clear();
  }

  async addRouteSubscription() {
    this.routeSubscription = this.router.events.subscribe(async event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      if (this.urlIsWatched(event.urlAfterRedirects)) {
        const callback = async () => {
          const storeId = await this.deliveryStorage.getStoreId();
          if (!storeId) {
            return;
          }
          const store = await this.storeService.getById(storeId);
          await this.addressService.setStore(store);
        };
        setTimeout(callback, 300);
      }
    });
  }

  async removeRouteSubscription() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = null;
    }
  }

  urlIsWatched(url: string): boolean {
    if (!url || url.length === 0) {
      return false;
    }
    for (const watchedUrl of this.watchedRoutes) {
      if (url.indexOf(watchedUrl) >= 0) {
        return true;
      }
    }
    return false;
  }

  async updateUser(): Promise<void> {
    const user = await this.userService.get();
    if (user.IsGuest) {
      await this.authStorage.saveIsGuest();
    } else {
      await this.authStorage.removeIsGuest();
    }
    await this.authStorage.saveProviderType(user.ProviderType);
  }


  async navigate(): Promise<void> {
    if (await this.authService.isDefaultPassword()) {
      await this.navController.navigateRoot('/profile/password-reset');
      return;
    }
    await this.updateShowAppointments();
    const updateOnly = await this.getHasSeenModal()
      || await this.authStorage.isGuest()
      || await this.authStorage.isChip()
      || await this.userService.hasRole()
      || !(await this.deliveryStorage.showAppointments());
    const selectedAddress = await this.addressService.getSelectedDeliveryAddressAndUpdateStore();
    await this.timeSlotService.setSelected(await this.timeSlotService.getSelected());
    // if (updateOnly) {
    //   return;
    // }
    await this.openDeliveryTypeSelect(selectedAddress);
  }

  async updateShowAppointments() {
    const value = await this.globalSettingService.getShowCustomerAppointments();
    return this.deliveryStorage.saveShowAppointments(value);
  }

  async openDeliveryTypeSelect(currentAddress: Address): Promise<void> {
    await this.setHasSeenModal();
    const deliveryType = await this.deliveryStorage.getDeliveryType();
    const modal = await this.modalController.create({
      component: DeliveryTypeSelectModalPage,
      componentProps: {
        SelectedDeliveryType: deliveryType,
        SelectedAddress: currentAddress,
        ShowClose: true
      },
      cssClass: 'height-90',
      backdropDismiss: false,
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response?.data?.Redirect) {
      await this.router.navigate(response.data.Redirect);
    }
  }

  async setHasSeenModal() {
    return this.storageService.save('last-seen-delivery-selection', moment().format('YYYY-MM-DD'));
  }

  async getHasSeenModal(): Promise<boolean> {
    const date = await this.storageService.get('last-seen-delivery-selection');
    if (!date || date != moment().format('YYYY-MM-DD')) {
      return false;
    }
    return true;
  }

  async clear() {
    return this.storageService.remove('last-seen-delivery-selection');
  }

}
